import { Button, Card, Input, Select, Typography, Option } from "@material-tailwind/react";
import axios from "axios";
import { useState } from "react";
import { POST_WARRANTIES_URL } from "../constants";
import { Warranty } from "../types/Warranty";
import { WarrantyCert } from "./warranty-cert";
import logos from "../img/logos-horizontal.jpeg";
import { itemsData } from "../assets/data";

export const WarrantyForm = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false);

    const [formData, setFormData] = useState<Warranty>(
        {
            name: "",
            phone: "",
            serialNumber: "",
            modelNumber: "",
            address: "",
            dealer: "",
            notes: "",
            item: ""
        }
    );
    const [warrantyData, setWarrantyData] = useState({})
    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleNumberChange = (event: any) => {
        const { name, value, validity } = event.target;

        if (validity.valid) {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }

    }

    const handleSelectChange = (event: any) => {
        setFormData((prevFormData) => ({ ...prevFormData, 'item': event }));
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsWaiting(true)
        const { name, phone, item }: Warranty = formData;
        if (!name?.length || !phone?.length || !item?.length) {
            setErrorMessage('Please fill in all the required details')
            setIsWaiting(false)
        }
        else {
            axios.post(POST_WARRANTIES_URL, {
                data: formData,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            }).then((res) => {
                const { data } = res;
                if (data.error === true) {
                    setErrorMessage(data.message)
                } else {
                    setIsSubmitted(true);
                    setWarrantyData(data)
                }
                setIsWaiting(false)
            })
                .catch((e) => {
                    console.log(e)
                    setErrorMessage('Error occurred, please try again later')
                    setIsWaiting(false)
                })
        }

    }


    return (
        <div className="container mx-auto warranty-form mt-20">
            {isSubmitted
                ?
                <WarrantyCert props={warrantyData} />
                :
                <Card className="p-10" shadow={true}>
                    {/* <Typography variant="h2" color="blue-gray">
                        National Pro
                    </Typography> */}
                    <div>
                        <img src={logos} alt="logo" className="logo-style" />
                    </div>
                    <Typography variant="h4" color="blue-gray">
                        Warranty Registration
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        Enter the required details to register.
                    </Typography>
                    <Typography color="red" className="mt-1 font-normal">
                        {errorMessage}
                    </Typography>
                    <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit}>
                        <div className="mb-4 flex flex-col gap-6">
                            <Input size="lg" name="name" label="Name*" onChange={handleChange} />
                            <Input size="lg" name='phone' pattern="[0-9]*" label="Phone*" onChange={handleNumberChange} />
                            <Input size="lg" name='serialNumber' label="Serial Number" onChange={handleChange} />
                            <Input size="lg" name='modelNumber' label="Model Number" onChange={handleChange} />
                            <Select label="Select Item" name="item" onChange={handleSelectChange}>
                                {
                                    itemsData?.filter((el) => el.id !== 'all').map((el) => {
                                        return (
                                            <Option value={el.id} key={el.id}>{el.text}</Option>
                                        )
                                    })
                                }

                            </Select>
                            <Input size="lg" name='notes' label="Notes" onChange={handleChange} />
                            <Input size="lg" name='dealer' label="Dealer's name" onChange={handleChange} />
                            <Input size="lg" name='address' label="Address" onChange={handleChange} />
                        </div>
                        <Button type="submit" color="red" className="mt-6" fullWidth disabled={isWaiting}>
                            Register
                        </Button>
                    </form>
                </Card>
            }
        </div>

    )
}