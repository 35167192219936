export const itemsData = [
    {
        id:'all',
        text: 'All'
    },
    {
        id:'refrigerator',
        text: 'Refrigerator'
    },
    {
        id:'freezer',
        text: 'Freezer'
    },
    {
        id:'washing-machine',
        text: 'Washing machine'
    },
    {
        id:'washer-dryer',
        text: 'Washer and Dryer'
    },
    {
        id:'ac',
        text: 'AC'
    },
    {
        id:'tv',
        text: 'TV'
    },
    {
        id:'microwave',
        text: 'Microwave'
    },
    {
        id:'air-fryer',
        text: 'Air fryer'
    },
    {
        id:'cooker',
        text: 'Cooker'
    },
    {
        id:'oven',
        text: 'Oven'
    },
    {
        id:'dryer',
        text: 'Dryer'
    },
    {
        id:'dishwasher',
        text: 'Dishwasher'
    },
    {
        id:'water-dispenser',
        text: 'Water dispenser'
    },
    {
        id:'air-curtain',
        text: 'Air curtain'
    },
    {
        id:'air-cooler',
        text: 'Air cooler'
    },
    {
        id:'vacuum-cleaner',
        text: 'Vacuum cleaner'
    },
    {
        id:'fan',
        text: 'Fan'
    },
    {
        id:'gas-heater',
        text: 'Gas heater'
    },
    {
        id:'patio-heater',
        text: 'Patio heater'
    },
    {
        id:'home-theater',
        text: 'Home theater'
    },
    {
        id:'oil-heater',
        text: 'Oil heater'
    },
    {
        id:'hob',
        text: 'Hob'
    },
    {
        id:'other',
        text: 'Other'
    }
]

 /* <Option value="refrigerator">Refrigerator</Option>
                                <Option value="freezer">Freezer</Option>
                                <Option value="washing-machine">Washing machine</Option>
                                <Option value="air-curtain">Washer and Dryer</Option>
                                <Option value="ac">AC</Option>
                                <Option value="tv">TV</Option>
                                <Option value="microwave">Microwave</Option>
                                <Option value="air-fryer">Air fryer</Option>
                                <Option value="air-curtain">Cooker</Option>
                                <Option value="air-curtain">Oven</Option>
                                <Option value="dryer">Dryer</Option>
                                <Option value="air-curtain">Dishwasher</Option>
                                <Option value="water-dispenser">Water dispenser</Option>
                                <Option value="air-curtain">Air curtain</Option>
                                <Option value="air-curtain">Air cooler</Option>
                                <Option value="air-curtain">Vacuum cleaner</Option>
                                <Option value="air-curtain">Fan</Option>
                                <Option value="air-curtain">Gas heater</Option>
                                <Option value="air-curtain">Patio heater</Option>
                                <Option value="air-curtain">Home theater</Option>
                                <Option value="air-curtain">Oil heater</Option>
                                <Option value="air-curtain">Hob</Option>
                                <Option value="air-curtain">Other</Option> */