import { Typography } from "@material-tailwind/react"
import logos from "../img/logos-horizontal.jpeg";
export const WarrantyCert = ({ props }: any) => {

    if (!props?.data) {
        return (
            <div></div>
        )
    }
    const { name, purchaseDate, phone, serialNumber, address, modelNumber, url, item, notes, dealer } = props?.data
    const classes = "p-4 border-b border-blue-gray-50";
    return (
        <div className="container mx-auto warranty-form mt-20">
            <div>
                <img src={logos} alt="logo" className="logo-style" />
            </div>
            <table className="w-full min-w-max table-auto  border-separate border border-slate-500">
                <tbody>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Name / اسم
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {name}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Phone / رقم التليفون
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {phone}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Address / عنوان
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {address}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Serial Number / الرقم التسلسلي
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {serialNumber}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Model Number / رقم الموديل
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {modelNumber}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Item / الصنف
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {item}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                notes / ملاحظات
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {notes}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Dealer's name / إسم الموزع
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {dealer}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes}>
                            <Typography variant="h4" color="blue-gray" className="font-bold">
                                Purchase Date / تاريخ الشراء
                            </Typography>

                            <Typography variant="h5" color="blue-gray" className="font-normal">
                                {purchaseDate}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-10">
                <a href={url} target="_blank" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 mt-20" rel="noreferrer">Download PDF</a>
            </div>


        </div>
    )
}