import './App.css';
import { WarrantyForm } from './components/warranty-form';

function App() {
  return (
    <div className="App">

      <WarrantyForm />
    </div>
  );
}

export default App;
