import { Button, Card, Input, Typography } from "@material-tailwind/react";
import axios from "axios";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LOGIN_WARRANTY_URL } from "../constants";


const Login = () => {
    const [formData, setFormData] = useState(
        {
            username: "",
            password: "",
        }
    );
    const navigate = useNavigate();
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    const isAuth: any = localStorage.getItem("authenticated")
    const [errorMessage, setErrorMessage] = useState('')

    if (JSON.parse(isAuth) === true) {
        return <Navigate replace to="/dashboard" />;
    }
    const handleSubmit = (e: any) => {
        e.preventDefault()
        const { username, password } = formData;

        if (!username || !password) {
            setErrorMessage('Invalid Username or Password')
        }
        else {
            axios.post(LOGIN_WARRANTY_URL, {
                data: formData,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            }).then((res) => {
                const { data } = res;
                if (data.error === true) {
                    setErrorMessage(data.message)
                } else {
                    localStorage.setItem("xhwysajadsl", "jh772hsdabj28");
                    navigate("/dashboard");
                }

            }).catch((e) => {
                console.log(e)
                setErrorMessage('Error occurred, please try again later')
            })
        }

    };
    return (
        <div className="container mx-auto warranty-form">
            <Card className="p-4">
                <Typography variant="h4" color="blue-gray">
                    Login
                </Typography>
                <Typography color="red" className="mt-1 font-normal">
                    {errorMessage}
                </Typography>
                <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit}>
                    <div className="mb-4 flex flex-col gap-6">
                        <Input size="lg" name="username" label="Username*" onChange={handleChange} />
                        <Input size="lg" name='password' label="Password*" onChange={handleChange} />
                    </div>
                    <Button type="submit" color="red" className="mt-6" fullWidth >
                        Login
                    </Button>
                </form>
            </Card>
        </div>
    )
};


export default Login;