import { Navigate } from "react-router-dom";


import { Card, Select, Typography, Option } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { GET_WARRANTIES_URL } from "../constants";
import { Warranty } from "../types/Warranty";
import { itemsData } from "../assets/data";

const TABLE_HEAD = ["Name", "Phone", "Address", "Model", "Serial Number", "Date", "Item", "Dealer", "notes"];


const Dashboard = () => {
    const authenticated: any = localStorage.getItem("xhwysajadsl");
    const [warranties, setWarranties] = useState([]);
    const [filterItem, setFilterItem] = useState("all");

    const handleSelectChange = (event: any) => {
        setFilterItem(event)
    }
    useEffect(() => {
        console.log('fet la hon')
        const url = `${GET_WARRANTIES_URL}?category=${filterItem}`;
        axios.get(url).then((response) => {
            setWarranties(response.data);
        }).catch((e) => setWarranties([]));
    }, [filterItem]);

    console.log({ warranties })
    if (authenticated === "jh772hsdabj28") {
        return (
            <div className="container mx-auto warranty-form mt-20">
                <Select label="Select Item" name="item" onChange={handleSelectChange}>
                    {
                        itemsData?.map((el: any) => {
                            return (
                                <Option value={el.id} key={el.id}>{el.text}</Option>
                            )
                        })
                    }
                </Select>
                <br />
                {warranties?.length > 0 ?
                    <Card className="overflow-scroll h-full w-full">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {warranties.map(({ name, phone, address, modelNumber,
                                    serialNumber, purchaseDate, dealer, notes, item }: Warranty, index) => {
                                    const isLast = index === warranties.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    const formattedDate = new Date(purchaseDate?._seconds * 1000).toLocaleString("en-GB")

                                    return (
                                        <tr key={serialNumber}>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {name}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {phone}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {address}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {modelNumber}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {serialNumber}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {formattedDate}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {item}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {dealer}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {notes}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                    : <p>No Data Found</p>}
            </div>
        );
    } else {
        return <Navigate replace to="/login" />;
    }
};
export default Dashboard;